import React from 'react';
import createPersistedState from 'use-persisted-state';
const useCounterState = createPersistedState('theme');

export const LIGHT_THEME = 'light-theme';
export const DARK_THEME = 'dark-theme';
export const ThemeContext = React.createContext();

function ThemeWrapper({ 
  isEnabled = true,
  children,
}) {
  const [theme, setTheme] = useCounterState(DARK_THEME);

  const applyTheme = (newTheme) => {
    document.getElementById('app').className = newTheme;
    setTheme(newTheme);
  }

  return (
    <ThemeContext.Provider value={{ theme, applyTheme }}>
      <div id="app" className={isEnabled ? theme : ''}>
        {children}
      </div>
    </ThemeContext.Provider>
  )
}

export default ThemeWrapper;