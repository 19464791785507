import React from 'react';
import { useIntl } from 'react-intl';
import { Divider, Space, Row, Col, Button, Typography } from 'antd';
import { LinkedinOutlined, TwitterOutlined, InstagramOutlined } from '@ant-design/icons';

import { ContentWrap } from '@/App';

const AboutContainer = () => {
  return (
    <Row>
      <Col offset={1} span={22}>
        <ContentWrap style={{maxWidth: 800}}>
          <Typography>
            <Typography.Title>
              Hi, I am Chris.
              <div style={{float: 'right', fontSize: '32px'}}>
                <Space size="middle" direction="horizontal" style={{width: '100%'}}>
                  <a href="https://www.instagram.com/bauschri2" target="_blank" alt="Instagram"><InstagramOutlined /></a>
                  {/* <a href="https://twitter.com/ChrisB_84" target="_blank"><TwitterOutlined /></a> */}
                  <a href="https://www.linkedin.com/in/christian-baus-a904735b" target="_blank"><LinkedinOutlined /></a>
                </Space>
              </div>

            </Typography.Title>
            <Divider />

            {/* <Typography.Paragraph>
              I am software developer and digital native, working with all different kind of technologies.
              This blog has the purpose of documenting some of my projects and finding across the internet.
              I like blogging about technologies, software development, bitcoin, health and some philosophical topics.
            </Typography.Paragraph> */}
            {/* <Typography.Paragraph>
              <ul>
                <li>
                  <Typography.Link href="/docs/spec/proximity">Principles</Typography.Link>
                </li>
                <li>
                  <Typography.Link href="/docs/spec/overview">Patterns</Typography.Link>
                </li>
                <li>
                  <Typography.Link href="/docs/resources">Resource Download</Typography.Link>
                </li>
              </ul>
            </Typography.Paragraph> */}


          </Typography>
        </ContentWrap>
      </Col>
    </Row>
  )
}

export default AboutContainer;
