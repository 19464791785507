import React, { useState, useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom'
import { IntlProvider } from 'react-intl';
import { useIntl } from 'react-intl';
import { Layout, Menu, Row, Col, Button, Typography } from 'antd';
import ReactGA from "react-ga4";
import CookieConsent, {
  getCookieConsentValue,
  Cookies
} from "react-cookie-consent";
import { BulbOutlined, BulbFilled } from '@ant-design/icons';

import { initGA } from './google';
import './App.less';
import ThemeWrapper, {ThemeContext, LIGHT_THEME, DARK_THEME} from './ThemeContext';

import Blog from '@/containers/Blog';
import About from '@/containers/About';

import BlogBitcoin from '@/containers/Blog/BlogBitcoin';
import BlogPrivacy from '@/containers/Blog/BlogPrivacy';



const MENU_WIDTH = 250;

const ToggleThemeButton = () => {
  const { theme, applyTheme } = React.useContext(ThemeContext);
  const altTheme = theme === LIGHT_THEME ? DARK_THEME : LIGHT_THEME;
  const toggle = () => {
    applyTheme(altTheme);
  }
  const icon = altTheme === LIGHT_THEME ? <BulbFilled /> : <BulbOutlined />;
  return (
    <div className="toggle-theme">
      <Button onClick={toggle} icon={icon} type="primary" size="large" />
    </div>
  )
}



const Header = () => {
  return (
    <Layout.Header>
      <div className="logo" />
      {/* <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
        <Menu.Item key="1">nav 1</Menu.Item>
        <Menu.Item key="2">nav 2</Menu.Item>
        <Menu.Item key="3" style={{marginLeft: 'auto'}}>nav 3</Menu.Item>
      </Menu> */}
      <div style={{
        position: 'fixed',
        right: 0,
        marginRight: '30px',
        marginLeft: '30px',
      }}>
        {/* <ToggleThemeButton /> */}
      </div>
    </Layout.Header>
  )
}

const Sider = () => {
  const location = useLocation();

  return (
    <Layout.Sider width={MENU_WIDTH} className="main-menu">
      <Menu
        mode="inline"
        defaultSelectedKeys={['1']}
        style={{ height: '100%', borderRight: 0 }}
      >
        <Menu.Item key="1"><Link to="/">Blog</Link></Menu.Item>
        <Menu.Item key="2"><Link to="/about">About</Link></Menu.Item>
      </Menu>

    </Layout.Sider>
  )
}

const App = () => {
  const [locale, setLocale] = useState(navigator.language);
  const [messages, setMessages] = useState({});
  const [isInitialized, setIsInitialized] = useState();

  const handleAcceptCookie = () => {
    initGA('G-FMNKSNVJFB');
    // if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    //   initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    // }
  };
  const handleDeclineCookie = () => {
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  async function loadLocaleData(locale) {
    let messages;
    switch (locale) {
      default:
        messages = await import('./lang/en.json')
        break;
    }
    setMessages(messages);
    setIsInitialized(true);
  }

  useEffect(() => {
    loadLocaleData(locale);
  }, [locale]);

  if (!isInitialized) {
    return <div />;
  }

  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <ThemeWrapper isEnabled={false}>
      <Router>
        <IntlProvider
          locale={locale}
          defaultLocale="en"
          messages={messages}
        >
          <Layout>
            {/* <Sider /> */}
            <Layout>
              {/* <Header /> */}
              <Layout>
                <Routes>
                  <Route exact path="/about" element={<About />} />

                  <Route path="/blog/34Z2D9G4-privacy" element={<BlogPrivacy />} />
                  <Route path="/blog/26B2C6A3-bitcoin-omega-long" element={<BlogBitcoin />} />
                  <Route path="*" element={<About />} />
                </Routes>
              </Layout>
            </Layout>
            {/* <CookieConsent 
              onAccept={handleAcceptCookie}
              onDecline={handleDeclineCookie}
              enableDeclineButton
            >
              This website uses cookies to enhance the user experience.
            </CookieConsent> */}
          </Layout>
        </IntlProvider>
      </Router>
    </ThemeWrapper>
  );
}

export default App;
