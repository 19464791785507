import React from 'react';
import { useIntl } from 'react-intl';
import { Layout, Menu, Row, Col, Button, Typography } from 'antd';

export const ContentWrap = ({
  style,
  children,
  ...rest
}) => {
  return (
    <Layout.Content
      style={{
        padding: 40,
        margin: 0,
        ...style,
      }}
      {...rest}
    >
      {children}
    </Layout.Content>    
  )
}