import React from 'react';
import { Image, Space, Row, Col, Anchor, Typography, Layout, Affix } from 'antd';

import headerImage from '@/images/security.jpeg'

const content = {
  link: '34Z2D9G4-privacy',
  header: 'Privacy & Security',
  content: [
    {
      id: 'components-anchor-demo-basic',
      header: 'Why does it matter?',
      text: (
        <React.Fragment>
          <Typography.Paragraph>
            Privacy is a basic right and a necessary protection in the digital space to avoid victimization and manipulation.
            In the digital age, your data is the new oil and big tech tries to get as much as they can to build out their products and services.
            More collected data means more power for these companies.
          </Typography.Paragraph>            
          <Typography.Paragraph>
            On the personal side, a bad handling of your privacy and security handling often means that you open doors for 
            malicious actors to steal personal data, hack your email account, encrypt your data or steal your bitcoin.          
          </Typography.Paragraph>
        </React.Fragment>
      )
    },
    {
      id: 'improve-my-security',
      header: 'What can i do to improve my security?',
      text: (
        <React.Fragment>
          <Typography.Paragraph>
            Privacy is a basic right and a necessary protection in the digital space to avoid victimization and manipulation.
            In the digital age, your data is the new oil and big tech tries to get as much as they can to build out their products and services.
            More collected data means more power for these companies.
          </Typography.Paragraph>            
          <Typography.Paragraph>
            On the personal side, a bad handling of your privacy and security handling often means that you open doors for 
            malicious actors to steal personal data, hack your email account, encrypt your data or steal your bitcoin.          
          </Typography.Paragraph>
        </React.Fragment>
      )
    },
  ],
}


const BlogPrivacy = () => {
  const [top, setTop] = React.useState(10);

  const menuOffset = 6;
  const contentSpan = 14;
  const maxWidthBlog = 1224;

  return (
    <div>
      <div style={{
        backgroundImage: `url(${headerImage})`,
        // backgroundPosition: '-100px 0px',
        backgroundSize: 'cover',
        height: '250px',
        width: '100%',
      }} />
      <Row id="blog-content" style={{maxWidth: maxWidthBlog, padding: '40px'}}>
        <Col xs={0} sm={0} md={6}>
          <Affix offsetTop={top}>
            <Anchor
              getContainer={() => document.getElementById("blog-content")}
              showInkInFixed={true}
              affix={true}
            >
              {content.content.map(ele => (
                <Anchor.Link href={`#${ele.id}`} title={`${ele.header}`} />
              ))}
            </Anchor>
          </Affix>      
        </Col>
        <Col xs={24} sm={24} md={18} style={{padding: '0 20px'}}>
          <Typography.Title>
            {content.header}
          </Typography.Title>          
          {content.content.map(ele => (
            <React.Fragment>
              <Typography.Title id={`#${ele.id}`} level={2}>
                {ele.header}
              </Typography.Title>
              {ele.text}
            </React.Fragment>
          ))}          
        </Col>
      </Row>    
    </div>
  )
}

export default BlogPrivacy;