import React from 'react';
import { Divider, Space, Row, Col, Anchor, Typography, Layout, Affix } from 'antd';

import "./Blog.less";

const BlogBitcoin = () => {
  const [top, setTop] = React.useState(10);

  const menuOffset = 6;
  const contentSpan = 14;
  const maxWidthBlog = 900;

  return (
    <div style={{padding: '40px'}}>
      <Affix offsetTop={top} style={{ position: 'absolute', top: '135px', left: '30px' }}>
        <Anchor
          getContainer={() => document.getElementById("blog-content")}
          showInkInFixed={true}
          affix={true}
        >
          <Anchor.Link href="#components-anchor-demo-basic" title="Bitcoin - the omega long " />
          <Anchor.Link href="#components-anchor-demo-static" title="Static demo" />
          <Anchor.Link href="#API" title="API">
            <Anchor.Link href="#Anchor-Props" title="Anchor Props" />
            <Anchor.Link href="#Link-Props" title="Link Props" />
          </Anchor.Link>
        </Anchor>
      </Affix>      
      <Row id="blog-content" style={{maxWidth: maxWidthBlog}}>
        <Col offset={menuOffset} span={contentSpan}>
          <Layout.Content>
            <Typography>
              <Typography.Title>
                Bitcoin - the omega long
              </Typography.Title>
              <Divider />
            </Typography>
          </Layout.Content>
        </Col>
        <Col offset={menuOffset} span={contentSpan}>
          <Layout.Content>
            <Typography.Title id="components-anchor-demo-basic" level={3}>
              Bitcoin - the omega long
            </Typography.Title>
            <Typography.Paragraph>
                
            </Typography.Paragraph>            
            <Typography.Paragraph>
              <ul>
                <li>
                  <Typography.Link href="/docs/spec/proximity">Principles</Typography.Link>
                </li>
                <li>
                  <Typography.Link href="/docs/spec/overview">Patterns</Typography.Link>
                </li>
                <li>
                  <Typography.Link href="/docs/resources">Resource Download</Typography.Link>
                </li>
              </ul>
            </Typography.Paragraph>            
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Title level={3}>
              Bitcoin - the omega long
            </Typography.Title>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Title id="Link-Props" level={3}>
              Bitcoin - the omega long
            </Typography.Title>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>
            <Typography.Paragraph>
              Test
            </Typography.Paragraph>

          </Layout.Content>
        </Col>
      </Row>    
    </div>
  )
}

export default BlogBitcoin;